import React from 'react';
import Chat from './Chat';

function App() {
  return (
      <div>
        <Chat />
      </div>
  );
}

export default App;