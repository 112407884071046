import {db} from './firebase';
import {collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch} from "firebase/firestore";

// Firestore에 유저 정보 저장 (없을 시 생성)
export const setUserNameInFirestore = async (userId, name) => {
    const userDocRef = doc(db, "users", userId); // 특정 유저 문서 참조

    const userDoc = await getDoc(userDocRef); // 문서가 존재하는지 확인
    if (!userDoc.exists()) {
        // 문서가 존재하지 않으면 새로 생성
        await setDoc(userDocRef, {name});
    }
};

// Firestore에서 유저 정보 가져오기
export const getUserNameFromFirestore = async (userId) => {
    const userDocRef = doc(db, "users", userId); // 특정 유저 문서 참조
    const userDoc = await getDoc(userDocRef);
    return userDoc.exists() ? userDoc.data().name : null;
};

// Firestore에서 메시지 구독 (가장 최근 1000개만 로딩)
export const subscribeToMessages = (callback) => {
    const q = query(
        collection(db, "chat_rooms", "default_room", "messages"), // 특정 채팅방의 메시지 컬렉션
        orderBy('sent_at', 'desc'),  // 최신 메시지를 먼저 가져옴
        limit(1000)                  // 최근 1000개 메시지
    );
    return onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        })).reverse();  // 원래 순서로 돌리기 위해 reverse()
        callback(messages);
    });
};

// Firestore에 메시지 추가하는 함수
export const addMessageToFirestore = async (message, userId, userName) => {
    try {
        const messagesCollection = collection(db, 'chat_rooms', 'default_room', 'messages'); // 메시지 컬렉션 참조
        await setDoc(doc(messagesCollection), {
            text: message,
            sender: userId,      // userId를 sender로 저장
            senderName: userName, // userName을 별도로 저장
            sent_at: new Date(),
        });
    } catch (error) {
        console.error('Error adding message:', error);
        throw new Error('메시지를 추가하는 중 오류가 발생했습니다.');
    }
};

// 유저가 대화명을 변경하면 그 유저가 작성한 모든 메시지의 대화명을 업데이트하는 함수
export const updateMessagesWithNewUserName = async (userId, newUserName) => {
    try {
        const messagesCollection = collection(db, 'chat_rooms', 'default_room', 'messages');
        const snapshot = await getDocs(query(messagesCollection, where('sender', '==', userId)));

        const batch = writeBatch(db); // 배치 작업 초기화

        snapshot.forEach((doc) => {
            const messageRef = doc.ref;
            batch.update(messageRef, {senderName: newUserName});
        });

        await batch.commit(); // 배치 작업을 커밋해서 모든 업데이트를 실행
        console.log('메시지의 대화명이 성공적으로 업데이트되었습니다.');
    } catch (error) {
        console.error('Error updating message senderName:', error);
        throw new Error('메시지의 대화명을 업데이트하는 중 오류가 발생했습니다.');
    }
};

// Firestore에서 유저의 대화명을 업데이트하는 함수 (문서가 없으면 생성)
export const updateUserNameInFirestore = async (userId, newUserName) => {
    try {
        const userRef = doc(db, 'users', userId); // 유저 문서 참조

        const userDoc = await getDoc(userRef);  // 문서 존재 여부 확인
        if (!userDoc.exists()) {
            // 문서가 없으면 새로 생성
            await setDoc(userRef, {name: newUserName});
        } else {
            // 문서가 있으면 업데이트
            await updateDoc(userRef, {
                name: newUserName  // userName 필드만 업데이트
            });
        }
    } catch (error) {
        console.error('Error updating user name:', error);
        throw new Error('대화명을 업데이트하는 중 오류가 발생했습니다.');
    }
};
