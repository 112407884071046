// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDxI53DT344EgNoD4tOJIJjdlyvlpP9NUU",
    authDomain: "firechat-5cc2f.firebaseapp.com",
    projectId: "firechat-5cc2f",
    storageBucket: "firechat-5cc2f.appspot.com",
    messagingSenderId: "453113756617",
    appId: "1:453113756617:web:1d729a6e32755e87dac618"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Export the app and db for use in other files
export { app, db };
