import React, {useEffect, useRef, useState} from 'react';
import {Button, Divider, Input, Layout, List, message, Modal, Typography} from 'antd';
import {EditOutlined, SendOutlined, UserOutlined} from '@ant-design/icons';
import {addMessageToFirestore, getUserNameFromFirestore, setUserNameInFirestore, subscribeToMessages, updateMessagesWithNewUserName, updateUserNameInFirestore} from './firebaseService';
import MessageRenderer from './MessageRenderer';
import {getUniqueUserId} from './userService';
import {handleCommand} from './commandHandler'; // 명령어 처리 함수 가져오기

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

function Chat() {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [nameLocked, setNameLocked] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newUserName, setNewUserName] = useState('');
    const messagesEndRef = useRef(null);

    // 고유 userId 생성 및 사용자 이름 가져오기
    useEffect(() => {
        const fetchUserIdAndName = async () => {
            try {
                const generatedUserId = await getUniqueUserId();  // FingerprintJS와 IP를 조합한 고유 식별자 생성
                setUserId(generatedUserId);

                const name = await getUserNameFromFirestore(generatedUserId);
                if (name) {
                    setUserName(name);
                    setNameLocked(true);
                } else {
                    setIsModalVisible(true);  // 대화명이 없으면 모달을 띄움
                }
            } catch (error) {
                message.error('사용자를 식별할 수 없어 서비스를 이용할 수 없습니다.');
            }
        };

        fetchUserIdAndName();
    }, []);

    useEffect(() => {
        const unsubscribe = subscribeToMessages(setMessages);
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.focus();
            const scrollContainer = messagesEndRef.current.parentNode;
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
    }, [messages]);

    const setUserNameAndLock = async () => {
        if (newUserName.trim() === '') {
            message.error('대화명을 입력하세요.');
            return;
        }

        try {
            if (userName) {
                // 기존 대화명이 존재하면 Firestore에서 대화명 업데이트
                await updateUserNameInFirestore(userId, newUserName);  // Firestore에서 유저의 대화명 업데이트
                await updateMessagesWithNewUserName(userId, newUserName);  // 유저가 작성한 모든 메시지의 대화명 업데이트

                // 대화명 변경을 시스템 메시지로 기록
                await addMessageToFirestore(
                    `${userName}님이 ${newUserName}(으)로 대화명을 변경했습니다.`,
                    'System',  // 시스템 메시지이므로 userId 대신 'System'
                    'System'   // 시스템 메시지이므로 userName도 'System'
                );
            } else {
                // 새로운 유저의 대화명을 Firestore에 저장
                await setUserNameInFirestore(userId, newUserName);
            }

            setUserName(newUserName);
            setNameLocked(true);
            setIsModalVisible(false);
            message.success('대화명이 저장되었습니다.');
        } catch (error) {
            message.error('대화명 변경 중 오류가 발생했습니다.');
        }
    };

    const sendMessage = async () => {
        if (newMessage.trim() === '') return;

        try {
            // 입력한 메시지의 \n을 <br />로 변환
            const formattedMessage = convertNewlinesToMarkdown(newMessage);

            if (newMessage.startsWith('/')) {
                // 명령어 처리
                await handleCommand(formattedMessage, userName);
            } else {
                // 일반 메시지 전송 (변환된 메시지 사용)
                await addMessageToFirestore(formattedMessage, userId, userName || 'Anonymous');
            }

            setNewMessage('');  // 메시지 전송 후 입력창 비우기
        } catch (error) {
            message.error('메시지 전송 중 오류가 발생했습니다.');
        }
    };

    const convertNewlinesToMarkdown = (text) => {
        const markdownNewline = '   \n';
        return text.replace(/\n/g, markdownNewline);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.ctrlKey) {
                setNewMessage(prevMessage => prevMessage + '\n');
            } else {
                e.preventDefault();
                sendMessage();
            }
        }
    };

    return (
        <Layout style={{height: '100vh', maxWidth: '1000px', backgroundColor: '#f0f2f5', margin: '0 auto'}}>
            <Header style={{
                backgroundColor: 'black',
                color: 'white',
                textAlign: 'center',
                padding: '0 10px',
                height: '50px',
                lineHeight: '50px'
            }}>
                <h2 style={{ color: 'yellow', margin: 0 }}>
                    Secret Code Chat Room
                </h2>
            </Header>

            <Content style={{ padding: '20px 5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                {nameLocked && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                        padding: '0px 5px',
                        borderRadius: '4px',
                    }}>
                        <Text strong style={{ fontSize: '18px' }}><UserOutlined /> {userName}</Text>
                        <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => setIsModalVisible(true)}
                        >
                            대화명 변경
                        </Button>
                    </div>
                )}

                <Divider dashed={true} style={{ margin: '0' }} />

                <div style={{ flex: 1, overflowY: 'auto', padding: '10px', borderRadius: '4px', marginBottom: '20px' }}>
                    <List
                        dataSource={messages}
                        renderItem={(message) => <MessageRenderer message={message} currentUserId={userId}/>}
                    />

                    <div ref={messagesEndRef} />
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <TextArea
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                        rows={2}
                        style={{ flex: 1, resize: 'none', height: 'auto' }}
                    />
                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={sendMessage}
                        style={{ flexShrink: 0, height: 'auto' }}
                    >
                        Send
                    </Button>
                </div>
            </Content>

            <Footer style={{
                textAlign: 'center',
                backgroundColor: 'black',
                color: 'white',
                padding: '10px 0',
                height: '10vh',
                fontSize: '12px'
            }}>
                Secret Code Serverless Chat Room ©2024<br/>
                Created by Geek with React & Firebase
            </Footer>

            <Modal
                title="대화명 입력"
                open={isModalVisible}
                onOk={setUserNameAndLock}
                onCancel={() => setIsModalVisible(false)}
                closable={false}  // 모달을 강제로 닫을 수 없도록 (초기 설정대로 유지)
            >
                <Input
                    placeholder="Enter your name..."
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                />
            </Modal>
        </Layout>
    );
}

export default Chat;
