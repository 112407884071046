import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Util from './Util'; // IP 가져오는 Util 함수

// FingerprintJS와 IP 주소를 조합하여 고유 식별자 생성
export const getUniqueUserId = async () => {
    // FingerprintJS 로드
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;

    // 사용자 디바이스의 고유 지문 생성
    const result = await fp.get();
    const deviceId = result.visitorId;  // FingerprintJS에서 생성된 고유한 deviceId

    // IP 가져오기
    const ip = await Util.getIp();
    if (!ip) {
        throw new Error('IP 주소를 가져올 수 없습니다.');
    }

    // DeviceId와 IP를 조합하여 고유 식별자 생성
    const userId = `${deviceId}-${ip}`;
    return userId;
};
