import {addMessageToFirestore} from './firebaseService'; // Firestore 서비스에서 메시지 추가 함수 가져오기
import {message} from 'antd'; // 에러 메시지 출력을 위한 Ant Design의 message 컴포넌트

// 명령어 처리 함수
export const handleCommand = async (command, userName) => {
    const commandParts = command.split(' ');
    const mainCommand = commandParts[0].toLowerCase();  // 명령어를 소문자로 처리

    switch (mainCommand) {
        case '/roll':
        case '/주사위':
            const max = commandParts[1] ? parseInt(commandParts[1], 10) : 6;
            if (isNaN(max) || max <= 1) {
                message.error('올바른 숫자를 입력해주세요.');
            } else {
                const roll = Math.floor(Math.random() * max) + 1;
                const rollMessage = `**${userName}** rolled a **${roll}** (1-${max})`;
                await addMessageToFirestore(rollMessage, 'System', 'System');
            }
            break;

        case '/shout':
        case '/외침':
            if (commandParts.length < 2) {
                message.error('외칠 내용을 입력해주세요.');
            } else {
                const shoutMessage = `**${userName}** shouts\n ## ${commandParts.slice(1).join(' ').toUpperCase()}`;
                await addMessageToFirestore(shoutMessage, 'ShoutBot', 'ShoutBot');
            }
            break;

        default:
            message.error('알 수 없는 명령어입니다.');
            break;
    }
};