import React from 'react';
import {Card, Divider, Typography} from 'antd';
import ReactMarkdown from 'react-markdown';

const {Text} = Typography;

const MessageRenderer = ({message, currentUserId}) => {
    const isSystemMessage = message.sender === 'System' || message.sender === 'ShoutBot';
    const isMyMessage = message.sender === currentUserId;  // 내 메시지인지 확인

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: isSystemMessage
                    ? 'center'           // 시스템 메시지는 가운데 정렬
                    : isMyMessage
                        ? 'flex-end'         // 내 메시지는 우측 정렬
                        : 'flex-start',      // 다른 사람의 메시지는 좌측 정렬
                marginBottom: '10px',
                width: '100%',
            }}
        >
            <Card

                size={isSystemMessage ? "default" : "small"}
                style={{
                    backgroundColor: isSystemMessage ? 'lightyellow' : isMyMessage ? '#E6F7FF' : '#FFF', // 내 메시지와 다른 사람 메시지 색상 구분
                    maxWidth: '60%',
                    borderRadius: '10px',
                    padding: '5px',
                    textAlign: 'left',  // 시스템 메시지는 가운데 정렬
                    width: "50vw"
                }}
            >
                {/* 대화명 표시 */}
                {!isSystemMessage ? (
                    <>
                        <Text strong>{message.senderName}</Text> {/* 대화명 표시 */}
                        <br/>
                    </>
                ) : (
                    <>
                        <Text strong italic>System</Text> {/* 대화명 표시 */}
                        <br/>
                    </>
                )}

                <Divider dashed={true} style={{margin: "5px 0px"}}></Divider>

                {/* 메시지 내용 */}
                <ReactMarkdown children={`${message.text}`}></ReactMarkdown>
                {/* 시간 표시 */}
                <div style={{fontSize: '8px', color: 'gray', textAlign: "right"}}>
                    {message.sent_at?.toDate().toLocaleString()}
                </div>
            </Card>
        </div>
    );
};

export default MessageRenderer;
