export const getIp = async () => {
    try {
        const response = await fetch('https://api64.ipify.org?format=json');
        const data = await response.json();
        return data.ip;  // IP 주소를 반환
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;  // 에러 발생 시 null을 반환
    }
};

export const anotherFunction = () => {
    console.log("This is another utility function.");
};

// 필요하다면 export default로 객체나 다른 기본 값을 내보낼 수 있음
const Util = {
    getIp,
    anotherFunction
};

export default Util;